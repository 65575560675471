import add from 'date-fns/add';
import compareAsc from 'date-fns/compareAsc';
import startOfDay from 'date-fns/startOfDay';
import sub from 'date-fns/sub';
import PropTypes from 'prop-types';
import { instanceOf } from 'prop-types';
import React from 'react';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import Media from 'react-media';
import numberOfDaysShowing, { sizes } from '../../js/calendarSizes';

export default class CustomWeek extends React.Component {
  range(date, rangeSize) {
    const start = startOfDay(date);
    const end = add(start, { days: rangeSize });

    let current = start;
    const range = [];

    while (compareAsc(current, end) < 1) {
      range.push(current);
      current = add(current, { days: 1 });
    }

    return range;
  }

  render() {
    const { date, onNavigate } = this.props;

    return (
      <Media
        queries={{
          small: `(max-width: ${sizes.medium - 1}px)`,
          medium: `(min-width: ${sizes.medium}px) and (max-width: ${
            sizes.large - 1
          }px)`,
          large: `(min-width: ${sizes.large}px) and (max-width: ${
            sizes.xlarge - 1
          }px)`,
          xlarge: `(min-width: ${sizes.xlarge}px) and (max-width: ${
            sizes.xxlarge - 1
          }px)`,
          xxlarge: `(min-width: ${sizes.xxlarge}px)`
        }}
        onChange={() => onNavigate('RESIZE')}
      >
        {matches => (
          <>
            {matches.small && (
              <TimeGrid {...this.props} range={this.range(date, 2)} />
            )}
            {matches.medium && (
              <TimeGrid {...this.props} range={this.range(date, 4)} />
            )}
            {matches.large && (
              <TimeGrid {...this.props} range={this.range(date, 7)} />
            )}
            {matches.xlarge && (
              <TimeGrid {...this.props} range={this.range(date, 7)} />
            )}
            {matches.xxlarge && (
              <TimeGrid {...this.props} range={this.range(date, 7)} />
            )}
          </>
        )}
      </Media>
    );
  }
}

CustomWeek.navigate = (date, action, { startLimit, endLimit }) => {
  const numberOfDays = numberOfDaysShowing();

  const previousDate = () => {
    const requestedDate = sub(date, { days: numberOfDays });

    // return the later of the start limit or the requested date
    return compareAsc(startLimit, requestedDate) > 0
      ? startLimit
      : requestedDate;
  };

  const nextDate = () => {
    const requestedDate = add(date, { days: numberOfDays });

    // return the earlier of the requested date or the end limit minus the number of Days showing
    return compareAsc(endLimit, add(requestedDate, { days: numberOfDays })) < 1
      ? sub(endLimit, { days: numberOfDays })
      : requestedDate;
  };

  const resizedDate = () => {
    // return the earlier of the current date or the end limit minus the number of Days showing
    return compareAsc(endLimit, add(date, { days: numberOfDays })) < 1
      ? sub(endLimit, { days: numberOfDays })
      : date;
  };

  switch (action) {
    case 'PREV':
      return previousDate();

    case 'NEXT':
      return nextDate();

    case 'RESIZE':
      return resizedDate();

    default:
      return date;
  }
};

CustomWeek.title = () => '';

CustomWeek.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  date: instanceOf(Date).isRequired
};
