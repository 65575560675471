const getHeaders = () => {
  const headers = new window.Headers({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  });

  const csrfElement = document.head.querySelector("[name='csrf-token']");
  const csrfToken = csrfElement && csrfElement.content;

  if (csrfToken) {
    headers.append('X-CSRF-Token', csrfToken);
  }

  return headers;
};

export const createRequest = ({ url, body, method = 'get' }) => {
  const request = new window.Request(url, {
    headers: getHeaders(),
    method,
    credentials: 'same-origin',
    dataType: 'json',
    body
  });

  return request;
};
