import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { formatMonthYear, formatTime } from '../js/dateFormat';

const DAYS_IN_WEEK = 7;

const TimeSlotPicker = ({ days = {}, formName, onChange, selected }) => {
  const dayKeys = useMemo(() => {
    return Object.keys(days);
  }, [days]);

  const [currentDay, setCurrentDay] = useState(dayKeys[0]);
  const [weekOffset, setWeekOffset] = useState(0);

  useEffect(() => {
    setCurrentDay(dayKeys[weekOffset * DAYS_IN_WEEK]);
  }, [weekOffset, dayKeys]);

  const weekStart = weekOffset * DAYS_IN_WEEK;
  const visibleDays = dayKeys.slice(weekStart, weekStart + DAYS_IN_WEEK);

  const handleSetDay = idx => {
    setCurrentDay(idx);
  };

  const handleDayKeyDown = (idx, e) => {
    if (e.keyCode === 13) {
      handleSetDay(idx);
    }
  };

  const goToNext = () => {
    setWeekOffset(weekOffset + 1);
  };

  const goToBack = () => {
    setWeekOffset(weekOffset - 1);
  };

  return (
    <>
      <div className="row py-sm-3">
        <span className="icon-button-wrapper col-1">
          <button
            type="button"
            className="icon-button"
            disabled={weekOffset < 1}
            onClick={goToBack}
          >
            <span className="icon icon-carot-left" />
          </button>
        </span>
        <span className="mx-auto col-8 text-center">
          <h2 className="aligned-center">
            {formatMonthYear(new Date(currentDay))}
          </h2>
        </span>
        <span className="icon-button-wrapper col-1 justify-content-end">
          <button
            type="button"
            className="icon-button"
            disabled={!dayKeys[(weekOffset + 1) * DAYS_IN_WEEK]}
            onClick={goToNext}
          >
            <span className="icon icon-carot-right" />
          </button>
        </span>
      </div>

      <div className="d-flex border-bottom border-grey mb-5">
        {visibleDays.map(day => {
          return (
            <div
              className="day-wrapper"
              tabIndex={0}
              key={day}
              role="button"
              onClick={() => handleSetDay(day)}
              onKeyDown={e => handleDayKeyDown(day, e)}
            >
              <h6
                id={format(new Date(day), 'EEE-dd')}
                className={`day-title ${day === currentDay && 'active'}`}
              >
                <span className="fs-1">{format(new Date(day), 'dd')}</span>
                <span className="mb-2">{format(new Date(day), 'EEE')}</span>
              </h6>
            </div>
          );
        })}
      </div>
      <div className="radio-group">
        {days[currentDay].map(timeslot => {
          return (
            <label className="btn-radio col-auto" key={timeslot}>
              <input
                type="radio"
                name={`${formName}[requested_time_range]`}
                value={timeslot}
                selected={selected === timeslot}
                onChange={onChange}
              />
              <span className="btn btn-outline-primary radio-label border-1">
                {formatTime(new Date(timeslot.split('/')[0]))}
              </span>
            </label>
          );
        })}
      </div>
    </>
  );
};

TimeSlotPicker.propTypes = {
  days: PropTypes.objectOf(PropTypes.unknown).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  formName: PropTypes.string
};

export default TimeSlotPicker;
