import { compareAsc, sub } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import numberOfDaysShowing from '../../js/calendarSizes';
import { formatMonthYear } from '../../js/dateFormat';

const Toolbar = props => {
  const { date, onNavigate, startLimit, endLimit } = props;

  const goToBack = () => {
    onNavigate('PREV');
  };

  const goToNext = () => {
    onNavigate('NEXT');
  };

  const disableBack = () => {
    return compareAsc(startLimit, date) >= 0;
  };

  const disableNext = () => {
    return (
      compareAsc(date, sub(endLimit, { days: numberOfDaysShowing() })) >= 0
    );
  };

  return (
    <div className="toolbar row py-sm-3">
      <span className="icon-button-wrapper col-1">
        <button
          type="button"
          className="icon-button"
          disabled={disableBack()}
          onClick={goToBack}
        >
          <span className="icon icon-carot-left" />
        </button>
      </span>
      <span className="mx-auto col-6 text-center">
        <h2 className="d-inline-block aligned-center">
          {formatMonthYear(date)}
        </h2>
      </span>
      <span className="icon-button-wrapper col-1 justify-content-end">
        <button
          type="button"
          className="icon-button"
          disabled={disableNext()}
          onClick={goToNext}
        >
          <span className="icon icon-carot-right" />
        </button>
      </span>
    </div>
  );
};

Toolbar.propTypes = {
  onNavigate: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  startLimit: PropTypes.instanceOf(Date),
  endLimit: PropTypes.instanceOf(Date)
};

export default Toolbar;
