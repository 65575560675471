const matchMedia = size => {
  return window.matchMedia(`(min-width: ${size}px)`).matches;
};

// maps to bootstrap breakpoints
// except for xxlarge - needed to be bigger
export const sizes = {
  medium: 768,
  large: 992,
  xlarge: 1200,
  xxlarge: 1600
};

export default function numberOfDaysShowing() {
  /* eslint-disable no-nested-ternary */
  return matchMedia(sizes.xxlarge)
    ? 8
    : matchMedia(sizes.xlarge)
      ? 8
      : matchMedia(sizes.large)
        ? 8
        : matchMedia(sizes.medium)
          ? 5
          : 3;
  /* eslint-enable no-nested-ternary */
}
