import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import NZLocale from 'date-fns/locale/en-NZ';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { dateFnsLocalizer } from 'react-big-calendar';

const locales = {
  'en-NZ': NZLocale
};

export default dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  timeGutterFormat: 'KK',
  locales
});
