import compareAsc from 'date-fns/compareAsc';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const CalendarHeader = ({
  date,
  handleSelect,
  handleDeselect,
  allDayAvails
}) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const start = new Date(year, month, day, 7);
  const end = new Date(year, month, day, 21);

  const isAllDay = allDayAvails.find(avail => {
    return !compareAsc(avail.startsAt, start) && !compareAsc(avail.endsAt, end);
  });

  const [allDay, setAllDay] = useState(isAllDay);

  // This ensures that allDay is updated on each render
  // and prevents a bug where otherwise changing weeks
  // would preserve a stale value for the checkbox
  useEffect(() => {
    setAllDay(isAllDay);
  }, [isAllDay]);

  function handleCheckboxChange(e) {
    setAllDay(!allDay);

    return e.target.checked
      ? handleSelect({ start, end })
      : handleDeselect({ id: isAllDay.id });
  }

  return (
    <span className="column" role="columnheader" aria-sort="none">
      <h3>{format(date, 'dd')}</h3>
      <span className="fw-light fs-5">{format(date, 'EEE')}</span>
      <div className="row all-day-cell mt-2">
        <label className="form-check-label form-check-inline">
          <input
            className="form-check-input"
            value="all-day"
            type="checkbox"
            onChange={handleCheckboxChange}
            checked={!!allDay}
          />
          &nbsp;All
        </label>
      </div>
    </span>
  );
};

CalendarHeader.propTypes = {
  handleDeselect: PropTypes.func,
  handleSelect: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  allDayAvails: PropTypes.arrayOf(PropTypes.unknown)
};

export default CalendarHeader;
