import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TimeSlotPickerWrapper from './TimeSlotPickerWrapper';

const AppointmentTimePicker = props => {
  const [showSecondStep, setShowSecondStep] = useState(
    props.showSecondStep || false
  );
  const candidateDetails = document.getElementById('candidate-details');
  const heroHeading = document.getElementById('hero-heading');
  const heroSubheading = document.getElementById('hero-subheading');

  const reportFormValidity = () => {
    const form = document.getElementById('new-appointment-form');

    return form.reportValidity();
  };

  const handleNext = () => {
    if (reportFormValidity()) {
      candidateDetails.style.display = 'none';
      heroHeading.textContent = 'Select an appointment';
      heroSubheading.textContent =
        'Please select a time from the Interview times below. You will need to allow at least 30 minutes for the Interview';

      setShowSecondStep(true);
    }
  };

  const handleBack = () => {
    candidateDetails.style.display = '';
    heroHeading.textContent = 'Tell us about you';
    heroSubheading.textContent =
      'Some personal information is required to deliver SLPI: NZSL assessments.  This includes contact details and associated organisations, such as your employer. All information is managed according to our Privacy Statement to protect your privacy.';
    setShowSecondStep(false);
  };

  const handlePreviousPage = () => {
    history.back();
  };

  return (
    <>
      {!showSecondStep && (
        <div className="row py-5 justify-content-between">
          <div className="col-6">
            <button
              type="button"
              className="btn btn-outline-primary w-100"
              tabIndex={0}
              onClick={handlePreviousPage}
            >
              Cancel
            </button>
          </div>

          <div className="col-6">
            <button
              type="button"
              className="btn btn-primary w-100"
              tabIndex={0}
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        </div>
      )}

      <TimeSlotPickerWrapper
        {...props}
        handleBack={handleBack}
        showSecondStep={showSecondStep}
        formName="appointment"
      />
    </>
  );
};

AppointmentTimePicker.propTypes = {
  showSecondStep: PropTypes.bool
};

export default AppointmentTimePicker;
