import PropTypes from 'prop-types';
import React from 'react';
import Media from 'react-media';
import { formatHourMinute } from '../../js/dateFormat';

// This component has been adapted from:
// https://github.com/jquense/react-big-calendar/blob/8e6c84db5241727548e065e4af83ef45caa734cb/src/TimeGridEvent.js

function stringifyPercent(v) {
  return typeof v === 'string' ? v : `${v}%`;
}

const EventWrapper = props => {
  const {
    style = {},
    event,
    accessors,
    onClick,
    onDoubleClick,
    onKeyPress
  } = props;
  const end = accessors.end(event);
  const start = accessors.start(event);

  const { height, top, width } = style;

  const eventStyle = {
    top: stringifyPercent(top),
    height: stringifyPercent(height),
    // Adding 10px to take events container right margin into account
    width: `calc(${width}% + 10px)`,
    fontSize: '16px',
    alignItems: 'center'
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      style={eventStyle}
      onKeyPress={onKeyPress}
      className="rbc-event"
    >
      <span className="col">
        <span className="row justify-content-center">Available</span>
        <Media
          queries={{
            small: '(min-width: 768px)' // bootstrap medium breakpoint
          }}
        >
          <span className="row small">
            {formatHourMinute(start)} - {formatHourMinute(end)}
          </span>
        </Media>
      </span>
    </div>
  );
};

EventWrapper.propTypes = {
  style: PropTypes.objectOf(PropTypes.unknown),
  event: PropTypes.objectOf(PropTypes.unknown),
  accessors: PropTypes.objectOf(PropTypes.unknown),
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onKeyPress: PropTypes.func
};
export default EventWrapper;
