import { add, compareAsc, differenceInHours, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Calendar, Views } from 'react-big-calendar';
import { createRequest } from '../util/createRequest';
import CalendarHeader from './availabilityCalendar/CalendarHeader';
import CustomWeek from './availabilityCalendar/CustomWeek';
import EventWrapper from './availabilityCalendar/EventWrapper';
import Toolbar from './availabilityCalendar/Toolbar';
import deDupeAvailabilities from './availabilityCalendar/deDupeAvailabilities';
import formatAvailabilities from './availabilityCalendar/formatAvailabilities';
import localizer from './availabilityCalendar/localizer';

const customDayPropGetter = date => {
  const dayOfWeek = date.getDay();
  const isWeekend = dayOfWeek === 6 || dayOfWeek === 0; // Saturday and Sunday

  return {
    className: isWeekend ? 'weekend-day' : ''
  };
};

const AvailabilityCalendar = ({ availabilities, block, user }) => {
  const [currentAvailabilities, setEvents] = useState(
    formatAvailabilities(availabilities)
  );

  const allDayAvails = currentAvailabilities.filter(avail => {
    return avail.startsAt.getHours() === 7 && avail.endsAt.getHours() === 21;
  });

  const handleSelect = async ({ start, end }) => {
    let calculatedEnd = end;

    if (differenceInHours(end, start) < 1) {
      calculatedEnd = add(start, { hours: 1 });
    }

    // to avoid showing an extra hour, we cut the calendar at 20:59
    // however the time should be shown as 21:00
    if (calculatedEnd.getMinutes() === 59) {
      calculatedEnd = add(calculatedEnd, { minutes: 1 });
    }

    const {
      availabilities: deDupedAvailabilities = [],
      startsAt,
      endsAt
    } = deDupeAvailabilities(currentAvailabilities, start, calculatedEnd);

    setEvents([
      ...deDupedAvailabilities,
      {
        startsAt,
        endsAt,
        id: Date.now()
      }
    ]);

    const newAvail = {
      startsAt,
      endsAt
    };

    const body = JSON.stringify({ availability: newAvail, userid: user.id });
    const response = await fetch(
      createRequest({ url: '/assessor/availabilities', body, method: 'POST' })
    );

    const updatedAvailabilities = await response.json();

    setEvents(formatAvailabilities(updatedAvailabilities));
  };

  const handleDeselect = async ({ id }) => {
    setEvents(
      currentAvailabilities.filter(x => {
        return x.id !== id;
      })
    );

    const response = await fetch(
      createRequest({
        url: `/assessor/availabilities/${id}`,
        method: 'DELETE'
      })
    );

    const updatedAvailabilities = await response.json();

    setEvents(formatAvailabilities(updatedAvailabilities));
  };

  const CalendarHeaderWrapper = ({ date }) => {
    return (
      <CalendarHeader
        handleSelect={handleSelect}
        handleDeselect={handleDeselect}
        date={date}
        allDayAvails={allDayAvails}
      />
    );
  };

  CalendarHeaderWrapper.propTypes = {
    date: PropTypes.instanceOf(Date)
  };

  const blockOpensAt = new Date(block.opens_at);
  const now = new Date();

  // is now after the block has opened?
  const defaultDate = compareAsc(now, blockOpensAt) > 0 ? now : blockOpensAt;

  // Block close dates are set to the end of that day, but we want
  // the limit to be inclusive, so we set it to the start of the next day
  const endLimit = startOfDay(
    add(new Date(block.bookings_close_at), { days: 1 })
  );

  return (
    <Calendar
      dayPropGetter={customDayPropGetter}
      defaultDate={defaultDate} // this is duplicated because it is needed in CustomWeek
      startLimit={defaultDate} // and default is not available on the props
      endLimit={endLimit}
      events={currentAvailabilities}
      startAccessor="startsAt"
      endAccessor="endsAt"
      min={new Date(1970, 1, 1, 7, 0, 0)}
      max={new Date(1970, 1, 1, 20, 59, 0)}
      localizer={localizer}
      formats={{
        timeGutterFormat: 'h bb'
      }}
      selectable
      onSelectSlot={handleSelect}
      onSelectEvent={handleDeselect}
      showMultiDayTimes={false}
      components={{
        eventWrapper: EventWrapper,
        toolbar: props => (
          <Toolbar {...props} startLimit={defaultDate} endLimit={endLimit} />
        ),
        header: CalendarHeaderWrapper
      }}
      drilldownView={null}
      style={{ height: 1000 }}
      defaultView={Views.WEEK}
      views={{ week: CustomWeek }}
    />
  );
};

AvailabilityCalendar.propTypes = {
  availabilities: PropTypes.arrayOf(PropTypes.unknown),
  block: PropTypes.objectOf(PropTypes.unknown),
  user: PropTypes.objectOf(PropTypes.unknown)
};

export default AvailabilityCalendar;
