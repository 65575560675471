import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import max from 'date-fns/max';
import min from 'date-fns/min';
import groupBy from 'lodash-es/groupBy';

const deDupeAvailabilities = (currentAvailabilities, start, end) => {
  const overlapping = groupBy(currentAvailabilities, avail => {
    return areIntervalsOverlapping(
      { start: avail.startsAt, end: avail.endsAt },
      { start, end }
    );
  });

  if (!overlapping.true) {
    return {
      availabilities: overlapping.false,
      startsAt: start,
      endsAt: end
    };
  }

  const startTimes = overlapping.true.map(avail => avail.startsAt);
  const endTimes = overlapping.true.map(avail => avail.endsAt);
  const calculatedStart = min([...startTimes, start]);
  const calculatedEnd = max([...endTimes, end]);

  return {
    availabilities: overlapping.false,
    startsAt: calculatedStart,
    endsAt: calculatedEnd
  };
};

export default deDupeAvailabilities;
