import format from 'date-fns/format';

const DEFAULT_TIME_FORMAT = 'h:mm aaa';
const DEFAULT_MONTH_YEAR_FORMAT = 'MMMM yyyy';
const DEFAULT_HOUR_MINUTE_FORMAT = 'h:mm';

const formatTime = (time, timeFormat = DEFAULT_TIME_FORMAT) =>
  format(time, timeFormat);

const formatMonthYear = (time, monthYearFormat = DEFAULT_MONTH_YEAR_FORMAT) =>
  format(time, monthYearFormat);

const formatHourMinute = (
  time,
  hourMinuteFormat = DEFAULT_HOUR_MINUTE_FORMAT
) => format(time, hourMinuteFormat);

export { formatTime, formatMonthYear, formatHourMinute };
