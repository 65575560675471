import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TimeSlotPicker from './TimeSlotPicker';

const TimeSlotPickerWrapper = props => {
  const {
    handleBack = () => {
      history.back();
    },
    availability,
    accessKey,
    showSecondStep = true,
    formName = 'appointment_time'
  } = props;

  const [selected, setselected] = useState(null);
  const [timeslots, setTimeslots] = useState(props.timeslots);

  useEffect(() => {
    if (!(availability && accessKey)) {
      return;
    }

    const availabileTimeslotsUrl = new URL(
      `${location.origin}/available_timeslots`
    );

    availabileTimeslotsUrl.searchParams.append('access_key', accessKey);
    (availability || []).forEach(id =>
      availabileTimeslotsUrl.searchParams.append(`availability_ids[]`, id)
    );

    fetch(availabileTimeslotsUrl)
      .then(r => r.json())
      .then(setTimeslots);
  }, [availability, accessKey]);

  if (!showSecondStep) {
    return null;
  }

  const onChange = e => {
    setselected(e.target.value);
  };

  if (!timeslots) {
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="spinner-border text-primary" role="status" />
        <div className="mt-3 fs-6 text-muted">Loading...</div>
      </div>
    );
  }

  if (!Object.keys(timeslots)[0]) {
    return <h2>Sorry there are no times currently available</h2>;
  }

  return (
    <>
      <TimeSlotPicker
        days={timeslots}
        showSecondStep={showSecondStep}
        formName={formName}
        onChange={onChange}
        selected={selected}
      />
      <p className="my-4">
        Assessment Interviews take approximately 20 minutes however additional
        time is provided for connecting and getting into the Zoom call. Changes
        can be made to booking times however please note that we cannot
        guarantee dates outside of the assessment block.
      </p>
      <div className="row py-sm-3 justify-content-between">
        <div className="col-6">
          <button
            onClick={handleBack}
            type="button"
            className="btn btn-outline-primary w-100"
          >
            Back
          </button>
        </div>

        <div className="col-6">
          <button
            disabled={!selected}
            className="btn btn-primary w-100"
            type="submit"
          >
            Send request
          </button>
        </div>
      </div>
    </>
  );
};

export default TimeSlotPickerWrapper;

TimeSlotPickerWrapper.propTypes = {
  timeslots: PropTypes.objectOf(PropTypes.unknown),
  availability: PropTypes.arrayOf(PropTypes.number),
  accessKey: PropTypes.string,
  handleBack: PropTypes.func,
  showSecondStep: PropTypes.bool,
  formName: PropTypes.string
};
