const AvailabilitiesFormatter = availabilities => {
  // eslint-disable-next-line camelcase
  return availabilities.map(({ starts_at, ends_at, id }) => {
    return {
      startsAt: new Date(starts_at),
      endsAt: new Date(ends_at),
      id
    };
  });
};

export default AvailabilitiesFormatter;
